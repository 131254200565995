@import url('https://fonts.googleapis.com/css?family=Dancing+Script&display=swap');
@import url('https://fonts.googleapis.com/css?family=Poppins:900&display=swap');

.App {
  text-align: center;
}


.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.buttonList{
  display: flex;
  flex-wrap: wrap;
}
.buttonList > button {
  cursor: pointer;
  width:100px;
  height:100px;
  flex: 1 1 100px;
  display: inline-block;
  margin: 1vw;
  border: none;
  font-family: 'Poppins', sans-serif;
  color: white;
  font-size: large;
}

.buttonList > button:active{
 opacity: 75%;
transition-duration: 0.1s;
}

.centerMiddle{
position:fixed;
  margin:auto;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  z-index: 1;
}

.noVertMargin{
  margin-top: 0px;
  margin-bottom: 0px;
}

.centerMiddle p{
  color: #ffc000;
  font-family: 'Poppins', sans-serif;
}

.welkom{
  font-size: 3vw;
  text-align: center;
}
.center{
  text-align: center;
}

.dogText{
  font-size: 50px;
  transition: 1s;
}

.gedicht{
    height: 70vh;
    width: 75vw;
    font-size: x-large;
    overflow-x:auto;
}
.moreDark{
  filter: brightness(40%);
}

.thinScrollBar::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.thinScrollBar::-webkit-scrollbar-button{
  height: 5px;
}
/* Track */
.thinScrollBar::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #e2e2e2;
}

/* Handle */
.thinScrollBar::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
}

/* Handle on hover */
.thinScrollBar::-webkit-scrollbar-thumb:hover{
  background: #555;
}
.thinScrollBar::-webkit-scrollbar-corner{
  display: none;
}
